import { useState } from "preact/hooks";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

export default function App() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}>
        Open Lightbox
      </button>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        plugins={[Fullscreen, Zoom, Counter]}
        slides={[
          {
            src: "https://shagr.at/content/photos/AP8BAxEp0bSrgx89kF0bxaL3rFUgEAu-Zv0ieOXcDfwUZCiWDQrfR9pqG8fPbv1qvzMTaXzKW6bIky0MsS-mp9rK5QnOaRADRg.jpg",
          },
          { src: "/image2.jpg" },
          { src: "/image3.jpg" },
        ]}
      />
    </>
  );
}
